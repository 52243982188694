/*
**********************************************
* ourchat colors
**********************************************
*/

$red-notification-color  : #EC1246;
$theme-purple            : #3D2570;
$theme-lpurple           : #F5F4F8;
$theme-black             : #060836;
$theme-grey             : #454B60;
$theme-grey2             : #9F9F9F;
$header-bg-color         : #ffffff;
$theme-white             : #ffffff;
$checkboxBg               : #C5BED4;
$green-Success            : #12B76A;
$green-Success-light      : rgba(18, 183, 106, 0.4);
$theme-red                : #E14339;
$theme-orange              : #EA7317;
$green-active               : #12B76A;
$grey-completed               : #4E4F70;

$warning-orange-bg: #FCECEB;
$warning-orange-text: #E14339;

$success-green-bg: #E7F8F0;
$success-green-text: #12B76A;


/*
**********************************************
* new defaults
**********************************************
*/
h1{
  font-weight: 700;
  font-size: 30px;
  line-height: 110%;
  /* identical to box height, or 33px */

  letter-spacing: -0.02em;
}
h2{
  font-weight: 700;
  font-size: 26px;
  line-height: 110%;
  /* identical to box height, or 33px */

  letter-spacing: -0.02em;
}
h3{
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  /* identical to box height, or 33px */

  letter-spacing: -0.02em;
}
h4{
  font-weight: 700;
  font-size: 22px;
  line-height: 110%;
  /* identical to box height, or 33px */

  letter-spacing: -0.02em;
}
h5{
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  /* identical to box height, or 33px */

  letter-spacing: -0.02em;
}
h6{
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  /* identical to box height, or 33px */

  letter-spacing: -0.02em;
}
.timelineControls{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  p{
    margin-bottom: 0;
    font-size: 14px;
  }
  .intervalDropdown{
      button{
        background: none;
        border: none;
        font-size: 16px;
        font-weight: 600;
        outline: none;
        box-shadow: none;
        color: $theme-grey;
        &:hover, &:focus{
          background: none;
          border: none;
          color: $theme-purple;
        }
      }
  }
}

/*
**********************************************
* new sections
**********************************************
*/

.box-outline{
  border: 1px solid $theme-grey2;
  padding: 32px 30px;
  background: #fff;
  border-radius: 5px;
  .boxHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6{
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 0;
      color: #454B60;
    }
    .link{
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.583333px;
      text-transform: uppercase;
      color: $theme-purple;
      text-decoration: none;
    }
  }
  .chatTilesContainer{
    margin-top: 15px;
    height: 100%;
    overflow-y: scroll !important;
    //padding-bottom: 200px;

    &::-webkit-scrollbar {
      width: 5px;
      display: none;
    }

    &::-webkit-scrollbar-track {
      background: $theme-lpurple;
    }

    &::-webkit-scrollbar-thumb {
      background: $theme-purple;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $theme-purple;
    }

    .tilesList{
      list-style: none;
      padding-left: 0;
      max-height: 600px;
      li{
        display: block;
        position: relative;
      }
      .tileComponent{
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        cursor: pointer;
        padding: 12px;
        .profileImgContainer{
          position: relative;
          //overflow: hidden;
          margin-right: 12px;
          .imgTile{
            width: 40px;
            height: 40px;
            overflow: hidden;
            position: relative;
            img{
              width: 40px;
              height: 40px;
              object-fit: cover;
              object-position: center;
              border-radius: 100%;
              overflow: hidden;
            }
          }
        }
        .profileDetails{
          //width: 80%;
          width: 100%;
          border-bottom: 1px solid #efefef;
          .ageTag{
            font-weight: 500;
            font-size: 10px;
            line-height: 110%;
            letter-spacing: -0.02em;
            text-transform: capitalize;
            color: $theme-black;

            padding: 3px 6px;
            background: rgba(6, 8, 54, 0.06);
            border-radius: 100px;
            margin-bottom: 5px;
            display: inline-block;
          }
          p{
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $theme-black;
            margin-bottom: 10px;
            opacity: 0.7;
          }
          .statusBar{
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            svg{
              transform: translateY(-1px);
              margin-right: 5px;
              width: 18px;
              height: 18px;
            }
            &.pending{
              color: $theme-orange;
              svg{
                rect{
                  fill: $theme-orange;
                }
              }
            }
            &.urgent{
              color: $theme-red;
              svg{
                rect{
                  fill: $theme-red;
                }
              }
            }
            &.unlocked{
              color: $success-green-text;
              svg{
                rect{
                  fill: $success-green-text;
                }
              }
            }
          }
          .profileDetailsHeader{
            width: 100%;
            .profileName{
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: $theme-black;
              display: block;
            }
          }

        }
        .chevContainer{
          align-items: center;
          //background: red;
          display: flex;
        }
        &.payouts{
          .chevContainer{
            border-bottom: 1px solid #efefef;
            .statusTag{
              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 15px;
              letter-spacing: -0.02em;
              color: $theme-black;
              min-width: 75px;
              max-width: 75px;
              text-align: center;
              padding: 3px 15px;
              background: rgba(6, 8, 54, 0.06);
              border-radius: 100px;
              margin-right: 30px;
              display: inline-block;
              text-transform: uppercase;

              &.pending{
                background: $warning-orange-bg;
                color: $warning-orange-text;
              }
              &.success{
                background: $success-green-bg;
                color: $success-green-text;
              }
            }
          }
        }
        &.isActive{
          .profileImgContainer{
            .imgTile{
              &:after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: $green-active;
                border: 2px solid #fff;
                box-sizing: border-box;
                right: 0;
                bottom: 0;
                border-radius: 100%;
              }
            }
          }
        }
        &.isOpen, &:hover, &:focus{
          background: #F5F4F8;
          border-radius: 6px;
          transition: all 0.5s ease;
        }
      }
    }
  }
}

.listNavs{
  li{
    position: relative;
    margin-right: 10px !important;
    a{
      font-weight: 600;
      font-size: 17px;
      line-height: 30px;
      text-decoration: none;
      color: $theme-grey;
      position: relative;

      &:hover, &:focus, &.active{
        color: $theme-purple;
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 3px;
        transition: all 0.5s ease;
      }
    }
  }
}

.userDetails{
  .profileDetails{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    .img-container{
      position: relative;
      overflow: hidden;
      img{
        width: 94px;
        height: 94px;
        object-fit: cover;
        object-position: center;
        border-radius: 100%;
        border: 1px solid red;
      }
    }
    .detailsContainer{
      margin-left: 24px;

      .Tag{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        color: $theme-black;

        background: rgba(6, 8, 54, 0.06);
        border-radius: 100px;
        margin-bottom: 5px;
        display: inline-block;

        min-width: 91px;
        max-width: 91px;
        text-align: center;
        padding: 3px 15px;
        background: rgba(6, 8, 54, 0.06);

        svg{
          transform: translateX(-10px);
          circle{
            fill: #454B60;
            fill-opacity: 1;
          }
        }
      }
    }
    .dropdownContainer{
      margin-left: auto;
    }
  }
}
.customTabs{
  .nav-pills{
    border-bottom: 1px solid $theme-grey2;
    .nav-item{
      margin-right: 10px;
      &:first-child{
       a{
         padding-left: 0;
       }
      }
      a{
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 5px 10px;
        color: $theme-grey;
        transition: all 0.5s ease;

        &:hover, &:focus, &.active{
          text-decoration: underline;
          background: none;
          border: none;
          box-shadow: none;
          outline: none;
          text-underline-offset: 9px;
          text-decoration-thickness: 3px;
          color: $theme-purple;
          transition: all 0.5s ease;
        }
      }
    }
  }
}

/*
* Tags Input container
*/
.TagsContainer{
  background: #fff;
  border-radius: 0px;
  border: 1px solid #C4C4C4;
  padding: 11px;
  min-height: 100px;
  .ReactTags__tagInputField{
    outline: none;
    box-shadow: none;
    background: none;
    border: none;
    width: 100%;
    margin-top: 5px;
  }
  .ReactTags__selected{
    .ReactTags__tag{
      background: rgba(61, 37, 112, 0.07);
      border-radius: 100px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $theme-purple;
      padding: 2.5px 10px;
      margin-right: 5px;
      button{
        border: none;
        outline: none;
        background: none;
        padding: 0;
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
}

.section-label{
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.box-bordered{
  border: 1px solid #454B60;
  border-radius: 8px;
  padding: 25px 30px;
  margin-bottom: 20px;
  .btn-group{
    text-align: right;
    width: 100%;
    display: block;
    .btn-primary{
        border-color: $theme-purple;
        background: transparent;
        color: $theme-purple;
        padding: 8px 15px;
        font-size: 14px;
        outline: none;
        box-shadow: none;
    }
    .btn-check:checked+.btn-primary{
      background: $theme-purple;
      color: $theme-white;
    }
  }

  .range-container{
    //input[type='range']{
    //  width: 80%;
    //
    //}
    //input[type='text']{
    //  width: 20%;
    //  display: inline;
    //}
  }
}