/*
**********************************************
* ourchat colors
**********************************************
*/
/*
**********************************************
* new defaults
**********************************************
*/
h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 110%;
  /* identical to box height, or 33px */
  letter-spacing: -0.02em;
}

h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 110%;
  /* identical to box height, or 33px */
  letter-spacing: -0.02em;
}

h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  /* identical to box height, or 33px */
  letter-spacing: -0.02em;
}

h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 110%;
  /* identical to box height, or 33px */
  letter-spacing: -0.02em;
}

h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  /* identical to box height, or 33px */
  letter-spacing: -0.02em;
}

h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  /* identical to box height, or 33px */
  letter-spacing: -0.02em;
}

.timelineControls {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
}
.timelineControls p {
  margin-bottom: 0;
  font-size: 14px;
}
.timelineControls .intervalDropdown button {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  box-shadow: none;
  color: #454B60;
}
.timelineControls .intervalDropdown button:hover, .timelineControls .intervalDropdown button:focus {
  background: none;
  border: none;
  color: #3D2570;
}

/*
**********************************************
* new sections
**********************************************
*/
.box-outline {
  border: 1px solid #9F9F9F;
  padding: 32px 30px;
  background: #fff;
  border-radius: 5px;
}
.box-outline .boxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box-outline .boxHeader h6 {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 0;
  color: #454B60;
}
.box-outline .boxHeader .link {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.583333px;
  text-transform: uppercase;
  color: #3D2570;
  text-decoration: none;
}
.box-outline .chatTilesContainer {
  margin-top: 15px;
  height: 100%;
  overflow-y: scroll !important;
}
.box-outline .chatTilesContainer::-webkit-scrollbar {
  width: 5px;
  display: none;
}
.box-outline .chatTilesContainer::-webkit-scrollbar-track {
  background: #F5F4F8;
}
.box-outline .chatTilesContainer::-webkit-scrollbar-thumb {
  background: #3D2570;
}
.box-outline .chatTilesContainer::-webkit-scrollbar-thumb:hover {
  background: #3D2570;
}
.box-outline .chatTilesContainer .tilesList {
  list-style: none;
  padding-left: 0;
  max-height: 600px;
}
.box-outline .chatTilesContainer .tilesList li {
  display: block;
  position: relative;
}
.box-outline .chatTilesContainer .tilesList .tileComponent {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 12px;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileImgContainer {
  position: relative;
  margin-right: 12px;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileImgContainer .imgTile {
  width: 40px;
  height: 40px;
  overflow: hidden;
  position: relative;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileImgContainer .imgTile img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  overflow: hidden;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails {
  width: 100%;
  border-bottom: 1px solid #efefef;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .ageTag {
  font-weight: 500;
  font-size: 10px;
  line-height: 110%;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #060836;
  padding: 3px 6px;
  background: rgba(6, 8, 54, 0.06);
  border-radius: 100px;
  margin-bottom: 5px;
  display: inline-block;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails p {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #060836;
  margin-bottom: 10px;
  opacity: 0.7;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .statusBar {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .statusBar svg {
  transform: translateY(-1px);
  margin-right: 5px;
  width: 18px;
  height: 18px;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .statusBar.pending {
  color: #EA7317;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .statusBar.pending svg rect {
  fill: #EA7317;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .statusBar.urgent {
  color: #E14339;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .statusBar.urgent svg rect {
  fill: #E14339;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .statusBar.unlocked {
  color: #12B76A;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .statusBar.unlocked svg rect {
  fill: #12B76A;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .profileDetailsHeader {
  width: 100%;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .profileDetails .profileDetailsHeader .profileName {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #060836;
  display: block;
}
.box-outline .chatTilesContainer .tilesList .tileComponent .chevContainer {
  align-items: center;
  display: flex;
}
.box-outline .chatTilesContainer .tilesList .tileComponent.payouts .chevContainer {
  border-bottom: 1px solid #efefef;
}
.box-outline .chatTilesContainer .tilesList .tileComponent.payouts .chevContainer .statusTag {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #060836;
  min-width: 75px;
  max-width: 75px;
  text-align: center;
  padding: 3px 15px;
  background: rgba(6, 8, 54, 0.06);
  border-radius: 100px;
  margin-right: 30px;
  display: inline-block;
  text-transform: uppercase;
}
.box-outline .chatTilesContainer .tilesList .tileComponent.payouts .chevContainer .statusTag.pending {
  background: #FCECEB;
  color: #E14339;
}
.box-outline .chatTilesContainer .tilesList .tileComponent.payouts .chevContainer .statusTag.success {
  background: #E7F8F0;
  color: #12B76A;
}
.box-outline .chatTilesContainer .tilesList .tileComponent.isActive .profileImgContainer .imgTile:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #12B76A;
  border: 2px solid #fff;
  box-sizing: border-box;
  right: 0;
  bottom: 0;
  border-radius: 100%;
}
.box-outline .chatTilesContainer .tilesList .tileComponent.isOpen, .box-outline .chatTilesContainer .tilesList .tileComponent:hover, .box-outline .chatTilesContainer .tilesList .tileComponent:focus {
  background: #F5F4F8;
  border-radius: 6px;
  transition: all 0.5s ease;
}

.listNavs li {
  position: relative;
  margin-right: 10px !important;
}
.listNavs li a {
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  text-decoration: none;
  color: #454B60;
  position: relative;
}
.listNavs li a:hover, .listNavs li a:focus, .listNavs li a.active {
  color: #3D2570;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 3px;
  transition: all 0.5s ease;
}

.userDetails .profileDetails {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}
.userDetails .profileDetails .img-container {
  position: relative;
  overflow: hidden;
}
.userDetails .profileDetails .img-container img {
  width: 94px;
  height: 94px;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  border: 1px solid red;
}
.userDetails .profileDetails .detailsContainer {
  margin-left: 24px;
}
.userDetails .profileDetails .detailsContainer .Tag {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #060836;
  background: rgba(6, 8, 54, 0.06);
  border-radius: 100px;
  margin-bottom: 5px;
  display: inline-block;
  min-width: 91px;
  max-width: 91px;
  text-align: center;
  padding: 3px 15px;
  background: rgba(6, 8, 54, 0.06);
}
.userDetails .profileDetails .detailsContainer .Tag svg {
  transform: translateX(-10px);
}
.userDetails .profileDetails .detailsContainer .Tag svg circle {
  fill: #454B60;
  fill-opacity: 1;
}
.userDetails .profileDetails .dropdownContainer {
  margin-left: auto;
}

.customTabs .nav-pills {
  border-bottom: 1px solid #9F9F9F;
}
.customTabs .nav-pills .nav-item {
  margin-right: 10px;
}
.customTabs .nav-pills .nav-item:first-child a {
  padding-left: 0;
}
.customTabs .nav-pills .nav-item a {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 5px 10px;
  color: #454B60;
  transition: all 0.5s ease;
}
.customTabs .nav-pills .nav-item a:hover, .customTabs .nav-pills .nav-item a:focus, .customTabs .nav-pills .nav-item a.active {
  text-decoration: underline;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  text-underline-offset: 9px;
  text-decoration-thickness: 3px;
  color: #3D2570;
  transition: all 0.5s ease;
}

/*
* Tags Input container
*/
.TagsContainer {
  background: #fff;
  border-radius: 0px;
  border: 1px solid #C4C4C4;
  padding: 11px;
  min-height: 100px;
}
.TagsContainer .ReactTags__tagInputField {
  outline: none;
  box-shadow: none;
  background: none;
  border: none;
  width: 100%;
  margin-top: 5px;
}
.TagsContainer .ReactTags__selected .ReactTags__tag {
  background: rgba(61, 37, 112, 0.07);
  border-radius: 100px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3D2570;
  padding: 2.5px 10px;
  margin-right: 5px;
}
.TagsContainer .ReactTags__selected .ReactTags__tag button {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  font-size: 16px;
  margin-left: 5px;
}

.section-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.box-bordered {
  border: 1px solid #454B60;
  border-radius: 8px;
  padding: 25px 30px;
  margin-bottom: 20px;
}
.box-bordered .btn-group {
  text-align: right;
  width: 100%;
  display: block;
}
.box-bordered .btn-group .btn-primary {
  border-color: #3D2570;
  background: transparent;
  color: #3D2570;
  padding: 8px 15px;
  font-size: 14px;
  outline: none;
  box-shadow: none;
}
.box-bordered .btn-group .btn-check:checked + .btn-primary {
  background: #3D2570;
  color: #ffffff;
}

